export enum BrowserFilterType {
  Chrome = 'Chrome',
  Edge = 'Edge',
}

export enum GadgetGuide {
  ComponentName = 'gadget-guide-response',
  DefaultContentAttribute = 'default-content',
}

export enum GadgetType {
  Camera = 'Camera',
  Microphone = 'Microphone',
  Speaker = 'Speaker',
}

export interface Brand {
  brand: string;
  version: string;
}

export interface GadgetGuideResponse {
  title: string;
  introduction: string;
  steps: GadgetGuideItemList[];
  additionalInfo: GadgetGuideItemList[];
}

export interface GadgetAgent {
  browserType: string;
  browserVersion: string;
  deviceModel: string;
  deviceName: string;
  osType: string;
  osVersion: string;
  gadgetType: GadgetType;
}

export interface UserAgentParser {
  getBrowser: () => { name: string; version: string };
  getDevice: () => { model: string; type: string; vendor: string };
  getOS: () => { name: string; version: string };
}

export type GadgetGuideItemList = Record<string, string | number>;

export type HTMLTemplate = HTMLElement & {
  content: { cloneNode: (v: boolean) => Node };
};
