import * as Sqrl from 'squirrelly';
import { UAParser } from 'ua-parser-js';

import { SessionService } from '../../services';
import state from '../../state';
import {
  GadgetAgent,
  GadgetGuide,
  GadgetGuideItemList,
  GadgetType,
  HTMLTemplate,
  UserAgentParser,
} from '../gadget-guide-response/gadget-guide.types';
import { buildInitialRequestPayload, handleGadgetGuideKeydown } from '../utils';

import template from './gadget-guide-response.html';
import './gadget-guide-response.scss';

export class XGadgetGuideResponse extends HTMLElement {
  public static doc: Document = document;
  public permissionsStatus: PermissionStatus;

  constructor() {
    super();

    // create a shadow root
    this.attachShadow({ mode: 'open' });

    // bind the context of the class to the event handlers
    this.getMediaStream = this.getMediaStream.bind(this);
    this.handleMediaError = this.handleMediaError.bind(this);
    this.handleMediaStream = this.handleMediaStream.bind(this);
    this.handlePermissionsChange = this.handlePermissionsChange.bind(this);
  }

  // custom element lifecycle callback
  public async connectedCallback() {
    this.getMediaStream();

    navigator.mediaDevices.addEventListener(
      'devicechange',
      this.getMediaStream
    );

    this.permissionsStatus = await XGadgetGuideResponse.getCameraPermission();
    this.permissionsStatus.addEventListener(
      'change',
      this.handlePermissionsChange
    );

    if (this.permissionsStatus.state === 'denied') {
      this.handleMediaError();
    }
  }

  // custom element lifecycle callback
  public disconnectedCallback() {
    navigator.mediaDevices?.removeEventListener(
      'devicechange',
      this.getMediaStream
    );

    this.permissionsStatus?.removeEventListener(
      'change',
      this.handlePermissionsChange
    );
  }

  public getMediaStream(): Promise<void> {
    return navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(this.handleMediaStream)
      .catch(this.handleMediaError);
  }

  public handleMediaStream(stream: MediaStream) {
    // must close the stream we opened so that MiSnap can capture video
    stream.getTracks().forEach((track) => track.stop());

    XGadgetGuideResponse.hideGadgetGuide(XGadgetGuideResponse.doc);
  }

  public handleMediaError() {
    XGadgetGuideResponse.resetGadgetGuideRenderCount();
    XGadgetGuideResponse.init(
      new UAParser(),
      XGadgetGuideResponse.doc,
      this.shadowRoot
    );

    XGadgetGuideResponse.showGadgetGuide(XGadgetGuideResponse.doc);
  }

  public handlePermissionsChange() {
    if (this.permissionsStatus.state === 'granted') {
      this.getMediaStream();
    } else {
      this.handleMediaError();
    }
  }

  public static hideGadgetGuide(doc: Document) {
    doc.removeEventListener('keydown', handleGadgetGuideKeydown);
    doc.querySelector('dialog').close();
  }

  // reset the gadget guide render count
  public static resetGadgetGuideRenderCount() {
    state.gadgetGuideRenderCount = 0;
  }

  public static showGadgetGuide(doc: Document) {
    const dialog = doc.querySelector('dialog');
    dialog?.showModal();
    dialog?.focus();

    doc.addEventListener('keydown', handleGadgetGuideKeydown);
  }

  // build a list of items
  public static buildItemList(
    items: GadgetGuideItemList[] = null,
    key: keyof GadgetGuideItemList = null,
    element = 'span'
  ) {
    return key
      ? items
          ?.map(
            (item: GadgetGuideItemList) =>
              `<${element}>${item[key]}</${element}>`
          )
          .join('')
      : '';
  }

  // clear the template section
  public static clearTemplateSection(shadow: ShadowRoot) {
    const section = shadow.querySelector('section');

    if (section) {
      section.innerHTML = '';
    }
  }

  public static getCameraPermission() {
    return navigator.permissions.query({
      name: 'camera' as PermissionName,
    });
  }

  // get the gadget guide instructions for the current user agent
  public static async getGadgetGuide({
    sha256,
    gadgetAgent,
  }: {
    sha256: string;
    gadgetAgent: GadgetAgent;
  }) {
    let title: string;
    let introduction: string;
    let steps: GadgetGuideItemList[];
    let additionalInfo: GadgetGuideItemList[];
    let hasError: boolean;
    let status: number;

    const storedGadgetAgentList = JSON.parse(
      sessionStorage.getItem('gadgetAgent')
    );

    // use stored GadgetAgent if it exists without error
    if (
      storedGadgetAgentList &&
      storedGadgetAgentList[sha256] &&
      storedGadgetAgentList[sha256].status !== 0
    ) {
      title = storedGadgetAgentList[sha256].response.title;
      introduction = storedGadgetAgentList[sha256].response.introduction;
      steps = storedGadgetAgentList[sha256].response.steps;
      additionalInfo = storedGadgetAgentList[sha256].response.additionalInfo;
      hasError = storedGadgetAgentList[sha256].hasError;
      status = storedGadgetAgentList[sha256].status;
    } else {
      try {
        const response = await SessionService.postGadgetGuide(gadgetAgent);

        title = response.data.title;
        introduction = response.data.introduction;
        steps = response.data.steps;
        additionalInfo = response.data.additionalInfo;
        status = response.status;
        hasError = false;
      } catch (error) {
        title = '';
        introduction = '';
        steps = [];
        additionalInfo = [];
        hasError = true;
        status = 0;
      }

      sessionStorage.setItem(
        'gadgetAgent',
        JSON.stringify({
          ...storedGadgetAgentList,
          [sha256]: {
            gadgetAgent,
            response: {
              title,
              introduction,
              steps,
              additionalInfo,
            },
            hasError,
            status,
          },
        })
      );
    }

    return {
      title,
      introduction,
      steps,
      additionalInfo,
      hasError,
      status,
    };
  }

  // initialize the gadget guide
  public static async init(
    uaParser: UserAgentParser,
    doc: Document,
    shadow: ShadowRoot
  ) {
    const loader = doc.createElement('div');

    doc
      .querySelector(`section[${GadgetGuide.DefaultContentAttribute}]`)
      ?.setAttribute(
        `${GadgetGuide.DefaultContentAttribute}--hidden`,
        `${GadgetGuide.DefaultContentAttribute}--hidden`
      );

    XGadgetGuideResponse.renderSkeletonLoader(
      loader,
      shadow,
      '<div class="skel-container"><div class="skel skel--has-pulse skel--text"></div></div>'
    );

    await XGadgetGuideResponse.renderGadgetGuide(
      uaParser,
      XGadgetGuideResponse.doc,
      shadow,
      loader
    );
  }

  // render the gadget guide
  public static async renderGadgetGuide(
    uaParser: UserAgentParser,
    doc: Document,
    shadow: ShadowRoot,
    loader: HTMLDivElement
  ) {
    // if we should not render gadget guide, show default content
    if (!this.shouldRenderGadgetGuide()) {
      this.showDefaultContent(doc, loader);

      // do not continue
      return;
    }

    XGadgetGuideResponse.clearTemplateSection(shadow);

    // get gadget guide payload
    const { sha256, gadgetAgent } = await buildInitialRequestPayload(
      uaParser,
      GadgetType.Camera,
      globalThis
    );

    // deconstruct gadget agent
    const {
      browserType,
      browserVersion,
      deviceModel,
      deviceName,
      osType,
      osVersion,
      gadgetType,
    } = gadgetAgent;

    // get gadget guide instructions
    const { title, introduction, steps, additionalInfo, hasError } =
      await this.getGadgetGuide({
        gadgetAgent: {
          browserType,
          browserVersion,
          deviceModel,
          deviceName,
          osType,
          osVersion,
          gadgetType,
        },
        sha256,
      });

    // show default content on caught error
    if (hasError) {
      this.showDefaultContent(XGadgetGuideResponse.doc, loader);
    }

    // build the gadget guide content
    const titleResult = title ? `<h1>${title}</h1>` : '';
    const introductionResult = introduction ? `<p>${introduction}</p>` : '';
    const instructionListItemResult = XGadgetGuideResponse.buildItemList(
      steps,
      'instruction',
      'li'
    );

    const instructionListResult = instructionListItemResult
      ? `<ol>${instructionListItemResult}</ol>`
      : '';

    const additionalListItemResult = XGadgetGuideResponse.buildItemList(
      additionalInfo,
      'text',
      'p'
    );

    // remove the skeleton loader
    loader.remove();

    // "stamp" the template
    const template: HTMLTemplate = doc.querySelector('template');
    const node = template?.content.cloneNode(true);
    shadow.append(node);

    // set the section content
    const section = shadow.querySelector('section');
    if (section) {
      shadow.querySelector(
        'section'
      ).innerHTML = `${titleResult}${introductionResult}${instructionListResult}${additionalListItemResult}`;
    }

    XGadgetGuideResponse.updateVideoModalBrowserType(doc, browserType);
  }

  // set the contents of the skeleton loader and append it to the parent element
  public static renderSkeletonLoader(
    loader: HTMLDivElement,
    shadow: ShadowRoot,
    contents: string
  ) {
    loader.innerHTML = contents;

    shadow.host.parentElement.append(loader);
  }

  // look in state and determine if we should render. we're setting a hard limit per session, including refreshes (f5)
  // via "session storage" as on a 403 of the endpoint, squirrelly would re-render the component infinitely
  public static shouldRenderGadgetGuide() {
    const renderCount = state.gadgetGuideRenderCount;
    const maxRenderCount = 5;

    if (renderCount < maxRenderCount) {
      state.gadgetGuideRenderCount = renderCount + 1;
    }

    return renderCount < maxRenderCount;
  }

  // remove the loader and show the default content
  public static showDefaultContent(doc: Document, loader: HTMLDivElement) {
    loader.remove();

    doc
      .querySelector(`div[${GadgetGuide.DefaultContentAttribute}]`)
      ?.removeAttribute(`${GadgetGuide.DefaultContentAttribute}--hidden`);
  }

  // update the browser type in the video modal
  public static updateVideoModalBrowserType(
    doc: Document,
    browserType: string
  ) {
    const videoModal = doc.getElementById('video-check-modal-browser-type');

    if (videoModal) {
      videoModal.textContent = browserType;
    }
  }
}

export function registerGadgetGuideResponse() {
  if (state.isVideoCheckModalV2Enabled) {
    if (!customElements.get(`x-${GadgetGuide.ComponentName}`)) {
      customElements.define(
        `x-${GadgetGuide.ComponentName}`,
        XGadgetGuideResponse
      );
    }

    Sqrl.templates.define(
      GadgetGuide.ComponentName,
      Sqrl.compile(template, { useWith: true })
    );
  }
}
