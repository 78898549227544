import { ApplicationInsightConfig } from '../configurations/application-insights.config';

const b2cClientId = '#{b2cClientId}#';
export const environment = {
  envName: '#{environment}#',
  apiBaseUrl: '#{apiBaseUrl}#',
  appInsights: {
    ...ApplicationInsightConfig,
    instrumentationKey: '#{appInsightsKey}#',
    connectionString: '#{appInsightsConnection}#',
  },
  authority: '#{authority}#',
  redirectUrl: '#{redirect_url}#',
  b2cClientId,
  consentScopes: [b2cClientId],
  pendo: {
    isEnabled: Boolean('#{pendoIsEnabled}#'),
    apiKey: '#{pendoApiKey}#'
  }
};
