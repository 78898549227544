import { UAParser } from 'ua-parser-js';
import './submission-failed.scss';
import html from './submission-failed.html';

import { Page } from '../page';
import state from '../../state';
import { DocumentType, Routes } from '../../shared/types';
import { getUserAgent } from '../../shared/utils';
import { RoutingService } from '../../services';
import { defaultOptions } from '../page-options';

export class SubmissionFailedPage extends Page {
  public get name() {
    return 'Submission Failed';
  }

  public get layoutOptions() {
    return {
      ...defaultOptions,
      hideFooter: false,
      hideStepTracker: true,
    };
  }

  constructor() {
    super(html);
  }

  getTemplateOptions() {
    return {
      browserType: getUserAgent(new UAParser()).getBrowser().name,
      failureMessage:
        state.documentType === DocumentType.Passport
          ? 'We were unable to process the photo.'
          : 'We were unable to process the photos.',
      instructionMessage:
        state.documentType === DocumentType.Passport
          ? 'Please retake the photo or try a different ID type.'
          : 'Please retake the photos or try a different ID type.',
    };
  }

  afterLoad() {
    document.getElementById('retake-button').addEventListener('click', () => {
      if (state.documentType === DocumentType.Passport)
        RoutingService.goToPage('capture/passport', true);
      else RoutingService.goToPage('capture/front', true);
    });

    document.getElementById('restart-button').addEventListener('click', () => {
      RoutingService.goToPage(Routes.LandingPage, true);
    });
  }
}
