import {
  Brand,
  BrowserFilterType,
  GadgetAgent,
  GadgetType,
  UserAgentParser,
} from './gadget-guide-response/gadget-guide.types';

import {
  NavigatorUA,
  UADataValues,
} from './gadget-guide-response/ua-client-hints.types';
import { CaptureType, DocumentType } from './types';
import { getLocationSearch } from './window';

export function distinct<T>(a: Array<T>): Array<T> {
  return a.filter((x, i, a) => a.indexOf(x) === i);
}

type CaptureTypeKey = keyof typeof CaptureType;
export function getCaptureTypeFromValue(
  value: CaptureType
): string | undefined {
  return Object.keys(CaptureType).find(
    (x) => CaptureType[x as CaptureTypeKey] === value
  );
}

export function asyncTimeout(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function getDocumentDisplayName(documentType: DocumentType): string {
  if (documentType === DocumentType.Passport) {
    return 'Passport';
  } else if (documentType === DocumentType.DriversLicense) {
    return "Driver's License";
  } else {
    return 'State ID';
  }
}

export function scrollToTop(): void {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}

export function removeStickyParent(): void {
  document.body.classList.remove('sticky__parent');
}

export function parseQueryParameters(): Record<string, string> {
  const queryParams: Record<string, string> = {};
  const search = getLocationSearch().split('?');
  if (search.length <= 1) {
    return queryParams;
  }

  const query = search[1];
  const queryParts = query.split('&');

  queryParts.forEach((query) => {
    const paramParts = query.split('=');
    if (paramParts.length > 1) {
      const queryParamKey = paramParts[0];
      const queryParamValue = paramParts.slice(1).join('=');
      queryParams[queryParamKey] = queryParamValue;
    }
  });
  return queryParams;
}

export function getRedirectUriQueryParameter() {
  const redirectUriKey = 'redirectUri';
  const queryParameters = getCapturedQueryParameters();

  if (queryParameters && queryParameters[redirectUriKey]) {
    return decodeURIComponent(queryParameters[redirectUriKey]);
  }

  return null;
}

function getCapturedQueryParameters() {
  return JSON.parse(sessionStorage.getItem('query-parameters'));
}

export function getUserAgent(uaParser: UserAgentParser): UserAgentParser {
  return {
    getBrowser: () => {
      return {
        name: uaParser.getBrowser().name.toLowerCase().includes('safari')
          ? 'Safari'
          : uaParser.getBrowser().name,
        version: uaParser.getBrowser().version,
      };
    },
    getDevice: () => {
      const getDeviceType = () => {
        return uaParser.getDevice().type?.toLowerCase() === 'mobile'
          ? 'Mobile'
          : 'Desktop';
      };

      return {
        model: uaParser.getDevice().model,
        type: getDeviceType(),
        vendor: uaParser.getDevice().vendor,
      };
    },
    getOS: () => {
      return {
        name: uaParser.getOS().name,
        version: uaParser.getOS().version,
      };
    },
  };
}

export function buildGadgetAgent(
  uaParser: UserAgentParser,
  partialGadgetAgent: Partial<GadgetAgent> = {}
): GadgetAgent {
  const parser = getUserAgent(uaParser);

  return {
    browserType: parser.getBrowser().name,
    browserVersion: parser.getBrowser().version,
    deviceModel: parser.getDevice().model,
    deviceName: parser.getDevice().type,
    osType: parser.getOS().name,
    osVersion: parser.getOS().version,
    gadgetType: GadgetType.Camera,
    ...partialGadgetAgent,
  };
}

export async function buildInitialRequestPayload(
  uaParser: UserAgentParser,
  gadgetType: GadgetType,
  gThis: typeof globalThis
): Promise<{ sha256: string; gadgetAgent: GadgetAgent }> {
  let gadgetAgent: GadgetAgent = getInitialGadgetAgent(uaParser);

  // add to GadgetAgent
  if (hasUserAgentData(gThis.navigator)) {
    const clientHints =
      (await getClientHints(gThis.navigator as NavigatorUA)) ?? {};

    const filteredBrands = filterBrowsers(uaParser, clientHints.brands);

    gadgetAgent = buildGadgetAgent(uaParser, {
      browserType: filteredBrands.browserType,
      browserVersion: filteredBrands.browserVersion,
      deviceModel: clientHints.model,
      deviceName: clientHints.mobile === true ? 'Mobile' : 'Desktop',
      osType: clientHints.platform,
      osVersion: clientHints.platformVersion,
      gadgetType,
    });
  }

  return isMacLikeOrIpad(gThis, gadgetAgent);
}

export function filterClientHintsBrands(
  name: string,
  brands: Brand[]
): Brand[] {
  return brands.filter(({ brand }) =>
    brand.toLowerCase().includes(name.toLowerCase())
  );
}

export function filterBrowsers(
  uaParser: UserAgentParser,
  brands: Brand[] = []
): {
  browserType: string;
  browserVersion: string;
} {
  const [isChrome] = filterClientHintsBrands(BrowserFilterType.Chrome, brands);
  if (isChrome) {
    return {
      browserType: BrowserFilterType.Chrome,
      browserVersion: isChrome.version,
    };
  }

  const [isEdge] = filterClientHintsBrands(BrowserFilterType.Edge, brands);
  if (isEdge) {
    return {
      browserType: BrowserFilterType.Edge,
      browserVersion: isEdge.version,
    };
  }

  return buildGadgetAgent(uaParser);
}

export function getClientHints(nav: NavigatorUA): Promise<UADataValues> {
  return nav.userAgentData.getHighEntropyValues([
    'platform',
    'platformVersion',
    'architecture',
    'model',
    'uaFullVersion',
  ]);
}

export function getInitialGadgetAgent(uaParser: UserAgentParser): GadgetAgent {
  const { deviceModel, deviceName, osType, osVersion } =
    buildGadgetAgent(uaParser);

  const { browserType, browserVersion } = filterBrowsers(uaParser);

  return {
    browserType,
    browserVersion,
    deviceModel,
    deviceName: deviceName?.toLowerCase() === 'mobile' ? 'Mobile' : 'Desktop',
    osType,
    osVersion,
    gadgetType: GadgetType.Camera,
  };
}

export function hasUserAgentData(nav: Navigator) {
  return 'userAgentData' in nav;
}

export const handleGadgetGuideKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    event.preventDefault();
  }
};

export async function getSHA256(val: string): Promise<string> {
  const msgBuffer = new TextEncoder().encode(val);
  const hashBuffer = await globalThis.crypto.subtle.digest(
    'SHA-256',
    msgBuffer
  );
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export async function isMacLikeOrIpad(
  gThis: typeof globalThis,
  initialGadgetAgent: GadgetAgent
): Promise<{ sha256: string; gadgetAgent: GadgetAgent }> {
  const iPadProperties = {
    hasTouchPoints: gThis.navigator.maxTouchPoints > 0,
    hasOnTouchStart: 'ontouchstart' in gThis,
    hasIOSFeatures: 'standalone' in gThis.navigator,
    isNotPhone: gThis.innerWidth > 750,
  };

  const isIPad =
    iPadProperties.isNotPhone &&
    iPadProperties.hasTouchPoints &&
    iPadProperties.hasOnTouchStart &&
    iPadProperties.hasIOSFeatures;

  const isMacLike =
    initialGadgetAgent.deviceModel?.toLowerCase().includes('mac') ||
    initialGadgetAgent.osType?.toLowerCase().includes('mac') ||
    initialGadgetAgent.osVersion?.toLowerCase().includes('mac');

  const gadgetAgent =
    isMacLike && isIPad
      ? {
          browserType: 'Safari',
          browserVersion: '',
          deviceModel: 'iPad',
          deviceName: 'Desktop',
          osType: 'iOS',
          osVersion: 'unknown',
          gadgetType: GadgetType.Camera,
        }
      : initialGadgetAgent;

  return {
    sha256: await getSHA256(JSON.stringify(gadgetAgent)),
    gadgetAgent,
  };
}
