import { UAParser } from 'ua-parser-js';
import { RoutingService, SessionService } from '../../services';
import { DocumentType, FeedbackButtonIconType, FeedbackButtonType, Routes, Step } from '../../shared/types';
import { getUserAgent } from '../../shared/utils';
import state from '../../state';
import { Page } from '../page';
import { defaultClientTheme } from '../../services/session/models/client-theme.model';
import html from './landing-page.html';
import './landing-page.scss';

export class LandingPage extends Page {
  public get name() {
    return 'Landing Page';
  }

  private readonly buttonClass = 'feedback-button';
  private readonly unselected = '--unselected';
  private readonly selected = '--selected';
  private hasSessionAlreadyStarted = false;
  public readonly isNewModal: boolean;
  public isAmrockTheme: boolean;
  public positiveButton: HTMLElement;
  public negativeButton: HTMLElement;
  public positiveIcon: HTMLElement;
  public negativeIcon: HTMLElement;

  constructor() {
    super(html);
    this.isNewModal = state.isVideoCheckModalV2Enabled ?? false;
  }

  getTemplateOptions(): Record<string, unknown> {
    return {
      firstName: state.firstName === '' ? 'Welcome' : state.firstName,
      browserType: getUserAgent(new UAParser()).getBrowser().name
    };
  }

  afterLoad(params: Record<string, unknown>): void {
    state.setVisitedSteps(params.request_ as string);
    this.setActiveStep(Step.ChooseDocument);

    // Only log session started on the first time this page is visited
    if (!this.hasSessionAlreadyStarted) {
      SessionService.postSessionStarted();
      this.hasSessionAlreadyStarted = true;
    }

    this.bindDocumentSelectionEvents();

    this.isAmrockTheme = state.clientTheme?.primaryColorHexCode === defaultClientTheme.primaryColorHexCode;
    if (this.isNewModal) {
      this.bindFeedbackSelectionEvents();
    }
  }

  bindDocumentSelectionEvents() {
    const driversLicenseButton = document.getElementById(
      'drivers-license-option'
    );
    const stateIdButton = document.getElementById('state-id-option');

    driversLicenseButton?.addEventListener('click', () => {
      this.selectDocumentType(DocumentType.DriversLicense);
    });

    stateIdButton?.addEventListener('click', () => {
      this.selectDocumentType(DocumentType.StateId);
    });
  }

  selectDocumentType(docType: DocumentType) {
    state.setDocumentType(docType);
    RoutingService.goToPage(Routes.CaptureLicenseId);
  }

  bindFeedbackSelectionEvents() {
    this.positiveButton = document.getElementById(FeedbackButtonType.Positive);
    this.negativeButton = document.getElementById(FeedbackButtonType.Negative);
    this.positiveIcon = document.getElementById(FeedbackButtonIconType.Positive);
    this.negativeIcon = document.getElementById(FeedbackButtonIconType.Negative);

    this.positiveButton?.addEventListener('click', this.onPositiveButtonClick);

    this.negativeButton?.addEventListener('click', this.onNegativeButtonClick);
  }

  onLeavePage() {
    this.positiveButton?.removeEventListener('click', this.onPositiveButtonClick);
    this.negativeButton?.removeEventListener('click', this.onNegativeButtonClick);
  }

  onPositiveButtonClick = () => {
    this.positiveButton?.classList.remove(this.buttonClass + this.unselected);
    this.positiveButton?.classList.add(this.buttonClass + this.selected);

    this.negativeButton?.classList.remove(this.buttonClass + this.selected);
    this.negativeButton?.classList.add(this.buttonClass + this.unselected);
    if (this.isAmrockTheme) {
      this.positiveIcon?.classList.remove(this.buttonClass + '__icon--feedback-positive-unselected');
      this.positiveIcon?.classList.add(this.buttonClass + '__icon--feedback-positive-selected');

      this.negativeIcon?.classList.remove(this.buttonClass + '__icon--feedback-negative-selected');
      this.negativeIcon?.classList.add(this.buttonClass + '__icon--feedback-negative-unselected');
    }
  };

  onNegativeButtonClick = () => {
    this.negativeButton?.classList.add(this.buttonClass + this.selected);
    this.negativeButton?.classList.remove(this.buttonClass + this.unselected);

    this.positiveButton?.classList.remove(this.buttonClass + this.selected);
    this.positiveButton?.classList.add(this.buttonClass + this.unselected);

    if (this.isAmrockTheme) {
      this.negativeIcon?.classList.add(this.buttonClass + '__icon--feedback-negative-selected');
      this.negativeIcon?.classList.remove(this.buttonClass + '__icon--feedback-negative-unselected');

      this.positiveIcon?.classList.remove(this.buttonClass + '__icon--feedback-positive-selected');
      this.positiveIcon?.classList.add(this.buttonClass + '__icon--feedback-positive-unselected');
    }
  };
}
