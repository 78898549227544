import { environment } from '../../environments/environment';
import { DEVICE_CODE_STORAGE_KEY } from '../../shared/constants';
import state from '../../state';
import { PendoAccount } from './pendo-account.model';
import { PendoVisitor } from './pendo-visitor.model';

declare global {
    interface Window {
        pendo: {
            initialize: (options: {
                visitor: PendoVisitor;
                account: PendoAccount;
            }) => void,
            identify:(visitor: PendoVisitor) => void
        };
    }
}

const snippet = `(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
  })('pendo-api-key');`;

export class PendoService {
    private isInitialized = false;

    getSnippet(apiKey: string): HTMLScriptElement {
        const scriptElement = document.createElement('script');
        scriptElement.innerHTML = snippet.replace('pendo-api-key', apiKey);
        return scriptElement;
    }

    initializePendo(): void {
        const environmentName = environment.envName === 'root' ? 'local' : environment.envName;
        const visitor: PendoVisitor = {
            id: sessionStorage.getItem(DEVICE_CODE_STORAGE_KEY),
            firstName: state.firstName ?? '',
        };
        const account: PendoAccount = {
            id: `IDVerification UI - ${environmentName}`,
            environmentName
        };
        if(!this.isInitialized){
            window.pendo.initialize({
                visitor: visitor,
                account: account,
            });
            this.isInitialized = true;
        } else {
            window.pendo.identify(visitor);
        }
    }
}

const pendoService = new PendoService();
export default pendoService;
